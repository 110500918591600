<template>
  <div class="l-wrapper">
    <div class=" q-pa-md">
      <q-select
        outlined
        label="Year"
        :options="data.availYear"
        v-model="options.selYear"
      ></q-select>
    </div>
    <q-scroll-area class="l-grow">
      <q-list>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredTarget"
          :key="i"
          v-ripple
        >
          <q-item-section>
            <q-item-label
              >{{ moment(val.start).format("DD MMM YYYY") }}&nbsp;/&nbsp;{{
                moment(val.end).format("DD MMM YYYY")
              }}</q-item-label
            >
            <q-item-label caption
              >new : {{ val.new }} / exist : {{ val.exist }}</q-item-label
            >
            <q-item-label caption>mean new / exist</q-item-label>
          </q-item-section>

          <q-item-section side>
            <div class="row q-gutter-x-sm">
              <q-btn
                flat
                label="DOWNLOAD"
                color="green-6"
                @click.stop.prevent="downloadExcell(val)"
              ></q-btn>
              <q-btn
                flat
                label="details"
                color="primary"
                @click.stop.prevent="
                  router.push(`/weekly-target/create/${val.id}`)
                "
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn
      label="New Target"
      color="primary"
      to="/weekly-target/create/-1"
    ></q-btn>
  </div>
</template>
<script>
import { computed, inject, reactive, ref } from "vue";
import { onMounted } from "vue";
import useWeeklyTarget from "./useWeeklyTarget";
import moment from "moment";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  setup() {
    const { getTarget, data, router, getYear, $q } = useWeeklyTarget();

    let options = reactive({
      selYear: "SEMUA",
    });

    const $http = inject("$http");

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getYear();
        data.availYear.unshift("SEMUA");
        await getTarget();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let filteredTarget = computed(() => {
      let target = JSON.parse(JSON.stringify(data.weekly_target));

      if (options.selYear != "SEMUA") {
        target = target.filter((val) => {
          return moment(val.start).format("YYYY") == options.selYear;
        });
      }

      return target;
    });

    let downloadExcell = async (val) => {
      $q.loading.show();
      let month = `${moment(val.start).format("D-M-YY")} - ${moment(
        val.end
      ).format("D-M-YY")}`;
      let title = `${moment(val.start).format("DD MMMM YYYY")} - ${moment(
        val.end
      ).format("DD MMMM YYYY")}`;

      let resp = await $http.get(
        `/weekly-target/report/${val.start}/${val.end}`
      );

      let tempvisit = JSON.parse(JSON.stringify(resp.data));

      const workbook = new ExcelJS.Workbook();
      let ws = workbook.addWorksheet("Visit");

      ws.mergeCells("A1", "I1");
      ws.getCell("A1").value = `Laporan Kunjungan ${title}`;

      ws.getRow(2).values = [
        "No",
        "Hari",
        "Customer",
        "New/Exist",
        "Ket.",
        "Kota",
        "Aplikasi",
        "Tipe Lem",
        "Notes",
      ];

      ws.columns = [
        { key: "c", width: 4 },
        { key: "date", width: 10 },
        { key: "name", width: 13 },
        { key: "customer_type", width: 10 },
        { key: "summary", width: 15 },
        { key: "city", width: 12 },
        { key: "application", width: 12 },
        { key: "tpe_lem", width: 10 },
        { key: "notes", width: 10 },
      ];

      let count = 1;
      for (let item of tempvisit) {
        let payload = {
          c: count++,
          date: moment(item.date).format("dddd, D/M/YY"),
          name: item.name,
          customer_type: item.customer_type,
          summary: item.summary,
          city: item.city,
          application: item.application,
          tipe_lem: "",
          notes: "",
        };

        ws.addRow({
          c: payload.c,
          date: payload.date,
          name: payload.name,
          customer_type: payload.customer_type,
          summary: payload.summary,
          city: payload.city,
          application: payload.application,
          tipe_lem: payload.tipe_lem,
          notes: payload.notes,
        });
      }

      // style the worksheet
      let len = tempvisit.length + 2;
      for (let i = 1; i <= 9; i++) {
        for (let j = 1; j <= len; j++) {
          let cell = `${String.fromCharCode(i + 64)}${j}`;
          if (j == 1) {
            ws.getCell(cell).alignment = {
              wrapText: true,
              vertical: "middle",
              horizontal: "center",
            };
            ws.getCell(cell).font = {
              bold: true,
              size: 18,
            };
          } else if (j == 2) {
            ws.getCell(cell).alignment = {
              wrapText: true,
              vertical: "middle",
              horizontal: "center",
            };
            ws.getCell(cell).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFCAFFFF" },
            };
            ws.getCell(cell).font = {
              bold: true,
            };
            ws.getCell(cell).border = {
              top: { style: "thick" },
              left: { style: "thick" },
              bottom: { style: "thick" },
              right: { style: "thick" },
            };
          } else {
            {
              ws.getCell(cell).alignment = { wrapText: true };
              ws.getCell(cell).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      try {
        saveAs(blob, `Laporan Kunjungan ${month}.xlsx`);
      } catch (err) {
        console.log(err);
      }
      $q.loading.hide();
    };

    return {
      data,
      router,
      options,
      filteredTarget,
      moment,
      downloadExcell,
    };
  },
};
</script>
